<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="" title="Settings" route="website.settings" />
      </v-col>
      <v-col cols="12" class="page-header">
        <h2 class="page-title">Taxes</h2>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="mt-5">
        <v-row>
          <v-col cols="12" md="4">
            <h3>Tax regions</h3>
            <p class="mt-3">
              Manage how your store charges sales tax. Check with a tax expert to understand your tax obligations.
            </p>
          </v-col>
          <v-col cols="12" md="8" v-if="isLoading">
            <SkeletonLoaders type="card-heading, list-item-three-line" />
          </v-col>
          <v-col v-else cols="12" md="8" class="elevation-form px-5 py-5">
            <div class="d-flex justify-space-between">
              <h3>Tax collection</h3>
              <v-btn @click="showModal" class="ml-auto router-link">
                Add a new country
              </v-btn>
            </div>
            <div class="mt-3">
              <v-simple-table v-if="taxes && taxes.filter(t => !t.state).length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Country</th>
                      <th>Threshold</th>
                      <th>Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="tx in taxes.filter(t => !t.state)" :key="tx.id">
                      <td>
                        <router-link :to="{ name: 'website.settings.taxes.update', params: { id: tx.id } }">
                          {{ tx.name }}
                        </router-link>
                      </td>
                      <td>{{ getCountryName(tx.country) }}</td>
                      <!-- <td>{{ countries.find(c => c.code === tx.country).name }}</td> -->
                      <td>{{ currency.priceFormat(tx.threshold || 0) }}</td>
                      <td>{{ tx.rate }}%</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <Empty v-else />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <modal model="modal" title="Add a new country" :callback="submit">
      <template slot="content">
        <v-row>
          <v-col cols="12">
            <h4>Add a new country for tax collection</h4>
          </v-col>
          <v-col cols="12">
            <h3>Country</h3>
            <v-combobox
              v-if="taxes && countries && countries.length > 0"
              item-text="name"
              item-value="code"
              placeholder="Select country"
              v-model="formData.country"
              @blur="$v.formData.country.$touch()"
              :items="countries.filter(c => taxes.findIndex(t => t.country === c.code) === -1)"
              hide-details
              :multiple="false"
            ></v-combobox>
            <p class="red--text" v-if="$v.formData.country.$dirty && !$v.formData.country.required">
              Country is required.
            </p>
          </v-col>
          <v-col cols="12">
            <h3>Threshold</h3>
            <v-text-field
              v-model="formData.threshold"
              hide-details
              :placeholder="currency.currencySign() + '0.00'"
            ></v-text-field>
            <small>Tax will be collected if order value is greater than or equal to threshold</small>
          </v-col>
          <v-col cols="12">
            <h3>Tax name</h3>
            <v-text-field
              placeholder="Sale tax"
              @blur="$v.formData.name.$touch()"
              v-model="formData.name"
              hide-details
            ></v-text-field>
            <p class="red--text" v-if="$v.formData.name.$dirty && !$v.formData.name.required">
              Tax name is required.
            </p>
          </v-col>
          <v-col cols="12">
            <h3>Tax rate</h3>
            <v-text-field
              placeholder="10"
              suffix="%"
              @blur="$v.formData.rate.$touch()"
              v-model="formData.rate"
              hide-details
            ></v-text-field>
            <p class="red--text mb-0" v-if="$v.formData.rate.$dirty && !$v.formData.rate.required">
              Tax rate is required.
            </p>
            <p class="red--text mb-0" v-if="$v.formData.rate.$dirty && !$v.formData.rate.decimal">
              Tax rate must be a number.
            </p>
          </v-col>
        </v-row>
      </template>
    </modal>
  </div>
</template>
<script>
import { orderApi } from '@/apis/order';
import { taxApi } from '@/apis/tax';
import { log } from 'handlebars';
import { required, minLength, maxLength, between, decimal } from 'vuelidate/lib/validators';
import currency from '@/helpers/currency';

export default {
  data() {
    return {
      modal: false,
      countries: null,
      formData: {
        country: '',
        threshold: 0,
        name: '',
        rate: '',
      },
      isLoading: false,
      isLoadingAction: false,
      taxes: [],
      currency,
    };
  },
  validations: {
    formData: {
      country: {
        required,
      },
      name: {
        required,
      },
      rate: {
        required,
        decimal,
      },
    },
  },
  computed: {},
  mounted() {
    this.loadCountries();
    this.loadData();
  },
  methods: {
    showModal() {
      this.formData = {
        country: '',
        threshold: '',
        name: '',
        rate: '',
      };
      this.modal = true;
      this.$v.formData.$reset();
    },
    async loadData() {
      try {
        this.isLoading = true;
        let resTaxes = await taxApi.list();
        this.taxes = resTaxes.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async loadCountries() {
      try {
        let countriesData = await orderApi.getCountry();
        this.countries = countriesData.data;
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$invalid) {
        let formData = { ...this.formData, country: this.formData?.country?.code };
        if (!formData.threshold) {
          formData.threshold = 0;
        }
        try {
          this.isLoadingAction = true;
          await taxApi.create(formData);
          this.modal = false;
          this.loadData();
        } catch (error) {
          this.isLoadingAction = false;
          console.log(error);
        }
      }
    },
    getCountryName(code) {
      if (!this.countries) return '';
      let country = this.countries.find(c => c.code === code);
      return country.name || '';
    },
  },
};
</script>
