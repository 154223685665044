class Currency {
  numberFormat(amount) {
    return Math.round(amount * 100) / 100;
  }

  priceFormat(amount) {
    const currencySign = localStorage.getItem('currencySign') || '$';
    return currencySign + this.numberFormat(amount);
  }

  currencySign() {
    return localStorage.getItem('currencySign') || '$';
  }

  currencyCode() {
    return localStorage.getItem('currencyCode') || 'USD';
  }
}

export default new Currency();
