let path = `/stores/taxes/`;
import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';
import router from '@/router';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);

function list(params) {
  return storeRequest.get(path + 'all', { params: params });
}

function getById(id) {
  return storeRequest.get(path + id);
}

function create(params) {
  return storeRequest.post(path, params);
}

function update(params) {
  return storeRequest.put(path + params.id, params);
}

function deleteById(id) {
  return storeRequest.delete(path + id);
}
function listUS(params) {
  return storeRequest.post(path + 'applyUSDefault');
}
export const taxApi = {
  list,
  getById,
  create,
  update,
  deleteById,
  listUS,
};
